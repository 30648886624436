import React from "react";
import boletin from "assets/icons/Reusable/Viewer_services/boletinICN.svg";
import caidaCeniza from "assets/icons/Reusable/Viewer_services/caidaCenizaICN.svg";
import danger_sismicity from "assets/icons/Reusable/Viewer_services/danger_sismicity.png";
import danger_system from "assets/icons/Reusable/Viewer_services/danger_system.png";
import digitals_sm from "assets/icons/Reusable/Viewer_services/digitals_sm.png";
import eq_query from "assets/icons/Reusable/Viewer_services/eq_query.png";
import felt from "assets/icons/Reusable/Viewer_services/felt.png";
import important_bolletin from "assets/icons/Reusable/Viewer_services/important_bolletin.png";
import last_eq from "assets/icons/Reusable/Viewer_services/last_eq.png";
import mf_tm from "assets/icons/Reusable/Viewer_services/mf_tm.png";
import DownloadIcon from "assets/icons/Reusable/Viewer_services/descargas.png";
import volcanes from "assets/icons/Reusable/Viewer_services/volcanes.svg";
import catalogador from "assets/icons/Reusable/Viewer_services/catalogador.svg";
import NSR_10 from "assets/icons/Reusable/Viewer_services/NSR_10.png";
import sh from "assets/icons/Reusable/Viewer_services/sh.png";
import sismicity_bolletin from "assets/icons/Reusable/Viewer_services/sismicity_bolletin.png";
import sm_query from "assets/icons/Reusable/Viewer_services/sm_query.png";
import strong_bolletin from "assets/icons/Reusable/Viewer_services/strong_bolletin.png";
import { Link } from "react-router-dom";
import logo_sgc_color from "assets/icons/Reusable/Logo_SGC.svg";

const SgcServices = () => {
  return (
    <>
    <div className = "services_header">
      <img src = { logo_sgc_color } alt = "sgc_color" />
    </div>
    <div className = "services_body">
      <a target="_blank" rel="noopener noreferrer" href="https://www2.sgc.gov.co/Noticias/Paginas/Boletines-extraordinarios.aspx">
        <img src={boletin} alt="eq_query" />
        <span>Boletines de actividad volcánica</span>
      </a>
      <a target="_blank" style={{ display: "none" }} rel="noopener noreferrer" href="https://www2.sgc.gov.co/sgc/volcanes/Paginas/galeria-emisiones-ceniza.aspx">
        <img src={caidaCeniza} alt="eq_query" />
        <span>Emisiones de gases y ceniza</span>
      </a>
      <a target="_blank" rel="noopener noreferrer" href="http://bdrsnc.sgc.gov.co/paginas1/catalogo/index.php">
        <img src={eq_query} alt="eq_query" />
        <span>​Consulta catálogo sísmico</span>
      </a>
      <a target="_blank" rel="noopener noreferrer" href="http://bdrsnc.sgc.gov.co/sismologia1/sismologia/focal_seiscomp_3/index.html">
        <img src={mf_tm} alt="mf_tm" />
        <span>​Mecanismo focal y Tensor momento sísmico</span>
      </a>
      <a target="_blank" rel="noopener noreferrer" href="https://sismosentido.sgc.gov.co/selector_sismo.html">
        <img src={felt} alt="felt" />
        <span>Sismos sentidos</span>
      </a>
      <a target="_blank" rel="noopener noreferrer" href="https://www2.sgc.gov.co/Publicaciones/Paginas/sismos-importantes.aspx">
        <img src={important_bolletin} alt="important_bolletin" />
        <span>​Boletín sismos importantes</span>
      </a>
      <a target="_blank" rel="noopener noreferrer" href="https://www2.sgc.gov.co/Publicaciones/Paginas/boletines-sismicidad.aspx">
        <img src={sismicity_bolletin} alt="sismicity_bolletin" />
        <span>Boletines de sismicidad</span>
      </a>
      <a target="_blank" rel="noopener noreferrer" href="https://www2.sgc.gov.co/Publicaciones/Paginas/boletines-movimiento-fuerte.aspx">
        <img src={strong_bolletin} alt="strong_bolletin" />
        <span>Boletines de movimiento fuerte</span>
      </a>
      <a target="_blank" rel="noopener noreferrer" href="http://bdrsnc.sgc.gov.co/paginas1/catalogo/index_rnac.php">
        <img src={sm_query} alt="sm_query" />
        <span>​Consulta catálogo aceleraciones</span>
      </a>
      <a target="_blank" rel="noopener noreferrer" href="https://trazas.sgc.gov.co/">
        <img src={digitals_sm} alt="digitals_sm" />
        <span>Sismogramas y acelerogr​amas digitales</span>
      </a>
      <a target="_blank" rel="noopener noreferrer" href="https://www2.sgc.gov.co/sgc/sismos/Paginas/Aprobacion-instrumentos.aspx">
        <img src={NSR_10} alt="NSR_10" />
        <span>Aprobación instrumentos para edificaciones (NSR-10)</span>
      </a>
      <a target="_blank" rel="noopener noreferrer" href="https://srvags.sgc.gov.co/jsviewer/Amenaza_Sismica_2020/">
        <img src={danger_sismicity} alt="danger_sismicity" />
        <span>Otros mapas de amenaza sísmica</span>
      </a>
      <a target="_blank" rel="noopener noreferrer" href="https://amenazasismica.sgc.gov.co/">
        <img src={danger_system} alt="danger_system" />
        <span>​Sistema de consulta de la amenaza sísmica de Colombia​</span>
      </a>
      <a target="_blank" rel="noopener noreferrer" href="https://sish.sgc.gov.co/visor/">
        <img src={sh} alt="sh" />
        <span>​Sismicidad Histórica</span>
      </a>
      <a target="_blank" rel="noopener noreferrer" href="https://sismo.sgc.gov.co:8443/">
        <img src={DownloadIcon} alt="Descarga de forma de onda" />
        <span>​Descarga de formas de onda</span>
      </a>
      <Link target="_blank" rel="noopener noreferrer" to="/sismos">
        <img src={last_eq} alt="last_eq" />
        <span>Sismos</span>
      </Link>
      <Link target="_blank" rel="noopener noreferrer" to="/volcanes">
        <img src={volcanes} alt="volcanes" />
        <span>Volcanes</span>
      </Link>
      <Link target="_blank" rel="noopener noreferrer" to="/catalogo">
          <img src={catalogador} alt="catalogador" />
        <span>Catálogo sísmico</span>
      </Link>
      <Link target="_blank" rel="noopener noreferrer" to="/historico-semanal">
          <img src={sismicity_bolletin} alt="sismicidad" />
        <span>Histórico Semanal</span>
      </Link>
    </div>
  </>
  );
}
  
export default SgcServices;
